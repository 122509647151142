import { Combobox } from "@headlessui/react";
import { useState, Fragment } from "react";
import Dialog from "@mui/material/Dialog";
import { MagnifyingGlassIcon } from "./icons/MagnifyingGlassIcon";
import { MAX_GUESSES } from "../lib/utils/constants";
import { gridLevelNumber } from "../lib/utils/gridLevels";
import { trackLevelStart } from "../lib/utils/analytics";
import { IconCheckLg } from "./icons/IconCheckLg";
import { UndoButton } from "./buttons/UndoButton";
import PickDialog from "./modals/PickDialog";
import { rarityScheme } from "../lib/utils/rarityScheme";
import useGameStore from "../lib/store/GameStore";
import usePersistentStore from "../lib/store/PersistentStore";
import { GridHeadshot } from "./modals/Headshots";
import { getPlayerById } from "../lib/utils/helpers";
import { UndoDialog } from "./modals/UndoDialog";

function getKeyOfMaxValue(obj) {
  let maxKey = null;
  let maxValue = Number.NEGATIVE_INFINITY;

  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] > maxValue) {
      maxKey = key;
      maxValue = obj[key];
    }
  }

  return maxKey;
}

export default function CellButton({ row, col, selectedTab }) {
  const [
    grid,
    setIsResultOpen,
    analyticsGameName,
    globalStats,
    undoModeActive,
    user,
    setIsRegisterOpen,
    riders,
  ] = useGameStore((state) => [
    state.grid,
    state.setIsResultOpen,
    state.analyticsGameName,
    state.globalStats,
    state.undoModeActive,
    state.user,
    state.setIsRegisterOpen,
    state.riders,
  ]);

  const [
    persistGuess,
    undoGuess,
    guessesLeft,
    incorrectGuesses,
    correctGuesses,
    undoUsed,
    seenRarity,
    setSeenRarity,
    cardMode,
  ] = usePersistentStore((state) => [
    state.persistGuess,
    state.undoGuess,
    state.guessesLeft,
    state.incorrectGuesses,
    state.correctGuesses,
    state.undoUsed,
    state.seenRarity,
    state.setSeenRarity,
    state.cardMode,
  ]);
  const storedCorrectGuessId = correctGuesses[row - 1][col - 1];
  const storedCorrectPlayer = getPlayerById(storedCorrectGuessId);
  const otherCorrectGuesses = correctGuesses.flat();
  const correctAnswers = Object.keys(
    grid.correctAnswersPlayerMap[row - 1][col - 1]
  ).map((playerId) => parseInt(playerId));
  const cellGuesses = incorrectGuesses[row - 1][col - 1];

  const [isOpen, setIsOpen] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(false);
  const [query, setQuery] = useState("");

  const correctAnswersPlayerMap =
    globalStats?.correctAnswersPlayerMap[row - 1][col - 1];
  const mostPopularId = getKeyOfMaxValue(correctAnswersPlayerMap);
  const mostPopularPlayer = getPlayerById(mostPopularId);

  const displayPlayer =
    selectedTab == "popular" ? mostPopularPlayer : storedCorrectPlayer;

  const selectedPct =
    displayPlayer && correctAnswersPlayerMap
      ? (correctAnswersPlayerMap[displayPlayer.id] / globalStats?.completed) *
        100
      : 0.1;

  const filteredPeople =
    query.length < 1
      ? []
      : riders
          .filter((player) => {
            return player.name
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLocaleLowerCase("en-US")
              .includes(
                query
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toLocaleLowerCase("en-US")
              );
          })
          .slice(0, 20);

  function closeModal() {
    setIsOpen(false);
    setSelectedPerson(false);
    setQuery("");
  }

  function openModal() {
    if (grid.levelNumber !== gridLevelNumber && !user) {
      // Premum feature check
      setIsRegisterOpen(true);
    } else if (
      guessesLeft > 0 &&
      !storedCorrectPlayer &&
      selectedTab != "popular"
    ) {
      setIsOpen(true);
    }
  }

  function undoHandler(player) {
    if (selectedTab == "my") {
      undoGuess(player, row, col);
    }
  }

  const [isUndoOpen, setIsUndoOpen] = useState(false);

  function closeUndoModal(isConfirmed) {
    if (isConfirmed === true) {
      // clickHandler();
      console.log("Undid guess");
    } else {
      closeModal();
      setIsResultOpen(true);
    }
    setIsUndoOpen(false);
  }

  function openUndoModal() {
    setIsUndoOpen(true);
  }

  function selectPlayer(player) {
    if (guessesLeft === MAX_GUESSES) {
      trackLevelStart({
        gameName: analyticsGameName,
        levelNumber: gridLevelNumber,
      });
    }
    setSelectedPerson(player);
    persistGuess(player, row, col);

    if (correctAnswers.includes(player.id)) {
      const pct = correctAnswersPlayerMap
        ? (correctAnswersPlayerMap[player.id] / globalStats?.completed) * 100
        : 0.1;
      const scheme = rarityScheme(pct);

      if (cardMode && (scheme.level > seenRarity || scheme.level == 9)) {
        closeModal();
        openPickModal();
        setSeenRarity(scheme.level);
      } else if (guessesLeft <= 1) {
        closeModal();
        setIsResultOpen(true);
      } else {
        setTimeout(() => {
          closeModal();
        }, 700);
      }
    } else if (guessesLeft <= 1 && !undoUsed) {
      setIsUndoOpen(true);
    } else if (guessesLeft <= 1) {
      closeModal();
      setIsResultOpen(true);
    }
  }

  const [isPickOpen, setIsPickOpen] = useState(false);
  function closePickModal() {
    setIsPickOpen(false);
  }

  function openPickModal() {
    if (cardMode && selectedTab == "my") {
      setIsPickOpen(true);
    }
  }

  const scheme = rarityScheme(selectedPct);
  const bg = cardMode ? scheme.class : "";

  return (
    <>
      {displayPlayer ? (
        <>
          <PickDialog
            isOpen={isPickOpen}
            closeModal={closePickModal}
            player={displayPlayer}
            selectedPct={selectedPct}
            row={row}
            col={col}
          />
          <div
            // href={`https://escapecollective.com/rider/?rider=${displayPlayer.id}`}
            // target="_blank"
            //onClick={() => undoHandler(displayPlayer)}
            className={`flex h-24 w-24 items-center justify-center overflow-hidden bg-cover bg-center dark:border-gray-950 ${
              selectedTab == "popular" && "border-slate-500"
            }
        } ${col < 3 && "border-r"} ${row < 3 && "border-b"}  ${
              selectedTab == "popular"
                ? "bg-mid-grey"
                : storedCorrectPlayer
                ? bg
                : ""
            } transition-colors duration-75 focus-visible:z-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-[#59d185] dark:hover:bg-gray-700 sm:h-36 sm:w-36 md:h-48 md:w-48`}
          >
            <div className="overflow group relative flex h-full w-full items-start justify-center">
              <GridHeadshot
                player={displayPlayer}
                silhouette={selectedTab === "no_spoilers"}
              />
              {!isNaN(selectedPct) && (
                <div className="absolute right-0 top-0 flex h-4 items-center rounded-bl-lg bg-black pl-1.5 pr-1 text-xxs text-white opacity-90 sm:h-5 sm:pr-1.5 sm:text-xs md:h-6 md:text-sm">
                  {selectedPct < 1
                    ? selectedPct.toFixed(1)
                    : selectedPct.toFixed(0)}
                  %
                </div>
              )}
              {selectedTab != "no_spoilers" && (
                <>
                  <div className="absolute bottom-0 left-0 right-0">
                    <div className="text-ellipsis whitespace-nowrap bg-black py-px text-xxs text-white opacity-90 sm:text-xs md:text-sm">
                      {displayPlayer.name}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <button
          onClick={openModal}
          className={`flex h-24 w-24 cursor-pointer items-center justify-center overflow-hidden dark:border-gray-950 ${
            selectedTab == "popular" && "border-slate-500"
          }
        } ${col < 3 && "border-r"} ${row < 3 && "border-b"}  ${
            selectedTab == "popular"
              ? "bg-mid-grey"
              : storedCorrectPlayer
              ? bg
              : isOpen
              ? "bg-yellow-200"
              : selectedTab == "no_spoilers"
              ? ""
              : guessesLeft > 0
              ? "hover:bg-yellow-50"
              : ""
          } transition-colors duration-75 focus-visible:z-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-[#59d185] dark:hover:bg-gray-700 sm:h-36 sm:w-36 md:h-48 md:w-48`}
        >
          {correctAnswers.length < 0 && (
            <div className="mb-1 flex flex-col items-center text-gray-500 sm:mb-2">
              {/* <IconWarningOutline className="item h-6 w-6 sm:h-8 sm:w-8 md:h-9 md:w-9" /> */}
              <strong className="text-xs sm:text-base md:text-lg">
                {correctAnswers.length} Players
              </strong>
            </div>
          )}
        </button>
      )}

      <Dialog
        open={isOpen}
        onClose={closeModal}
        transitionDuration={300}
        componentsProps={{
          backdrop: { style: { backgroundColor: "rgba(0, 0, 0, 0.25)" } },
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className="m-4 sm:m-6 md:m-20"
        sx={{
          "& .MuiDialog-container": {
            alignItems: "flex-start",
            margin: "0px",
            display: "block",
          },
        }}
        PaperProps={{
          sx: { margin: "0 auto", borderRadius: "0.75rem", maxWidth: "36rem" },
        }}
      >
        <Combobox value={selectedPerson} onChange={selectPlayer}>
          <div className="relative">
            {MagnifyingGlassIcon}
            <Combobox.Input
              autoFocus
              onChange={(event) => setQuery(event.target.value)}
              // displayValue={(person) => person.name}
              className="h-12 w-full border-0 bg-transparent pl-11 pr-9 text-black placeholder:text-gray-400 focus:outline-none focus:ring-0 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-200 sm:text-sm"
              // placeholder={`Search active riders...`}
              placeholder={`${correctAnswers.length} possible answers...`}
              autoComplete="off"
              autoCorrect="off"
            />
          </div>
          {(filteredPeople.length > 0 || query.length > 0) && (
            <Combobox.Options
              static={true}
              className={`z-50 max-h-72 scroll-py-2 overflow-y-auto border-t border-t-gray-100 py-2 text-sm text-black dark:bg-gray-700`}
            >
              {filteredPeople.length === 0 && query.length > 0 && (
                <li
                  className={`flex cursor-default select-none items-center justify-between px-4 py-2 leading-snug`}
                >
                  <div className="my-1 flex items-center justify-center text-black">
                    <span className="text-sm">
                      No active riders matching{" "}
                      <span className="font-bold">"{query}"</span> found
                    </span>
                  </div>
                </li>
              )}
              {filteredPeople.map((player) => (
                /* Use the `active` state to conditionally style the active option. */
                /* Use the `selected` state to conditionally style the selected option. */
                <Combobox.Option
                  key={player.id}
                  value={player}
                  as={Fragment}
                  disabled={
                    cellGuesses.includes(player.id) ||
                    otherCorrectGuesses.includes(player.id)
                  }
                >
                  {({ active, selected }) => (
                    <li
                      className={`flex cursor-default select-none items-center justify-between px-4 py-2 font-medium leading-snug ${
                        active ? "bg-soft-grey/50" : ""
                      }`}
                    >
                      <div
                        className={`my-1 flex items-center justify-center ${
                          !storedCorrectPlayer &&
                          cellGuesses.includes(player.id) &&
                          selected &&
                          "animate-shake"
                        } ${
                          storedCorrectPlayer?.id == player.id
                            ? "text-green-600"
                            : cellGuesses.includes(player.id)
                            ? "text-red-600 dark:text-red-400"
                            : otherCorrectGuesses.includes(player.id)
                            ? "text-slate-500"
                            : "dark:text-white"
                        }`}
                      >
                        <div className="mr-2 h-3 w-4">
                          <img src={`/img/flags/${player.country}.svg`} />
                        </div>
                        <span className="text-sm">{player.name}</span>
                      </div>
                      {storedCorrectPlayer?.id == player.id && (
                        <div className="fade-in -my-2 animate-correct px-2.5 text-sm font-medium text-green-600">
                          <IconCheckLg className="h-5 w-5" />
                        </div>
                      )}
                      {!selected &&
                        !cellGuesses.includes(player.id) &&
                        otherCorrectGuesses.includes(player.id) && (
                          <div className="px-2.5 text-sm font-medium text-slate-500">
                            Used
                          </div>
                        )}
                      {cellGuesses.includes(player.id) && !undoUsed && (
                        <UndoButton player={player} col={col} row={row} />
                      )}
                    </li>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </Combobox>
      </Dialog>
      <UndoDialog
        player={selectedPerson}
        row={row}
        col={col}
        isOpen={isUndoOpen}
        closeModal={closeUndoModal}
      />
    </>
  );
}
